/* globals wordpress_globals */
import "../styles/theme.scss";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import moduleDefinition from "@projModules/definition";
import { fetchSVG, loadIcons } from "@projLib/SVGSprite";
import convertDataAttributesToObject from "@projLib/convertDataAttributesToObejct";
/**
 * Async Modules: Modules are defined in ./modules/definitions.ts
 */
const bootstrap = {
    init: function () {
        this.asyncSVG.init();
        this.asyncModules.init(moduleDefinition);
    },
    asyncModules: {
        init: function (modules) {
            (modules !== null && modules !== void 0 ? modules : []).forEach(({ selector, moduleName, defaultArgs }) => {
                var _a;
                ((_a = document.querySelectorAll(selector)) !== null && _a !== void 0 ? _a : []).forEach((el) => {
                    var _a;
                    const formattedArgs = convertDataAttributesToObject((_a = el.dataset) !== null && _a !== void 0 ? _a : {});
                    const args = Object.assign(Object.assign({}, defaultArgs), formattedArgs);
                    return this.load(el, moduleName, args);
                });
            });
        },
        load: (el, moduleName, args) => {
            try {
                return import(`./modules/${moduleName}`).then((mod) => {
                    const Module = mod.default;
                    return new Module(el, args);
                });
            }
            catch (error) {
                const err = error;
                // eslint-disable-next-line no-console
                console.warn(err);
            }
            return false;
        },
    },
    asyncSVG: {
        init: function () {
            fetchSVG('./wp-content/themes/class/dist/sprite.svg')
                .catch((error) => console.warn(`[fetchSVG] ${error}`));
        }
    }
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
loadIcons();
