const convertDataAttributesToObject = (attributes) => {
    const returnedObject = {};
    const keys = Object.keys(attributes);
    Object.values(attributes).forEach((value, idx) => {
        const key = keys[idx];
        try {
            returnedObject[key] = JSON.parse(`${value}`);
        }
        catch (e) {
            returnedObject[key] = `${value}`;
        }
    });
    return returnedObject;
};
export default convertDataAttributesToObject;
